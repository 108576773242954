import { WithStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChatIcon from '@material-ui/icons/Chat';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MenuIcon from '@material-ui/icons/Menu';
import ScheduleIcon from '@material-ui/icons/Schedule';
import * as React from 'react';
import { Component, Fragment } from 'react';
import Candybar from './components/Candybar';
import CreateMessage from './components/CreateMessage';
import SelectTag from './components/SelectTag';
import SendMessages from './components/SendMessages';
import SocialMenu from './components/SocialMenu';

declare global {
  interface Window {
    fbAsyncInit: any;
    FB: any;
  }
  interface Fjs {
    readonly parentNode: HTMLImageElement;
    readonly insertBefore: HTMLImageElement;
  }
}

interface IAppState {
  FB: any;
  activestep: number;
  authed: boolean;
  fbpages: string[];
  fbuseraccesstoken: string;
  messengertags: object[];
  pageaccesstoken: string;
  selectedfbpage: string;
  selectedmessengertag: string;
  candybarmessage: string;
  candybartoggle: boolean;
  graphapi: object;
  messages: object[];
}

interface IProps extends WithStyles<typeof styles> {}

const styles = {
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  root: {
    flexGrow: 1,
  },
};

// TODO: Correctly replace all 'any' types
// TODO: Check if page if appoved for subscription messaging via
// me/messaging_feature_review endpoint
const DecoratedClass = withStyles(styles)(
  class App extends Component<IProps, IAppState> {
    public state = {
      FB: {},
      activestep: 0,
      authed: false,
      candybarmessage: '',
      candybartoggle: false,
      fbapp: {
        app: {
          appId: process.env.REACT_APP_FB_APP_ID,
          cookie: process.env.REACT_APP_FB_APP_COOKIE,
          scope: process.env.REACT_APP_FB_APP_SCOPE,
          version: process.env.REACT_APP_FB_APP_VERSION,
          xfbml: process.env.REACT_APP_FB_APP_XFBML,
        },
      },
      fbpages: [],
      fbuseraccesstoken: '',
      graphapi: {
        messengerbroadcastendpoint:
          process.env.REACT_APP_FB_GRAPHAPI_MESSENGER_BROADCAST_ENDPOINT ||
          'nope',
        messengercreativeendpoint:
          process.env.REACT_APP_FB_GRAPHAPI_MESSENGER_CREATIVE_ENDPOINT ||
          'nope',
        messengerlabelsendpoint:
          process.env.REACT_APP_FB_GRAPHAPI_MESSENGER_LABELS_ENDPOINT || 'nope',
      },
      messages: [],
      messengertags: [],
      pageaccesstoken: '',
      selectedfbpage: '',
      selectedmessengertag: '',
    };

    public render(): React.ReactNode {
      const { activestep, authed, selectedmessengertag } = this.state;

      const { classes } = this.props;

      return (
        <Fragment>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
              >
                <MenuIcon />
              </IconButton>
              <Typography color="inherit" className={classes.grow}>
                Messenger Bot Admin
              </Typography>
              {/* Test if the Facebook SDK has already finished loading and is
        present  */}
              {Object.keys(this.state.FB).length === 0 &&
              this.state.FB.constructor === Object ? (
                <p>Loading Facebook SDK...</p>
              ) : (
                <Fragment>
                  {/* Show login button if the user is not authenticated, or the other
              way around */}
                  {!authed ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleLogin}
                    >
                      Login
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.handleLogout}
                    >
                      Logout
                    </Button>
                  )}
                </Fragment>
              )}
            </Toolbar>
          </AppBar>
          <Paper square={true}>
            <Tabs
              value={0}
              // onChange={e}
              fullWidth={true}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab icon={<ChatIcon />} />
              <Tab icon={<ScheduleIcon />} />
              <Tab icon={<InsertChartIcon />} />
            </Tabs>
          </Paper>
          <MobileStepper
            variant="progress"
            steps={4}
            position="static"
            activeStep={this.state.activestep}
            className={classes.root}
            nextButton={
              <Button
                size="small"
                onClick={this.handleStepperNext}
                disabled={this.state.activestep === 5}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={this.handleStepperBack}
                disabled={this.state.activestep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
          <Fragment>
            {activestep === 0 ? (
              authed ? (
                <SocialMenu
                  token={this.state.pageaccesstoken}
                  select={this.handlePageSelection}
                  selected={this.state.selectedfbpage}
                  step={this.handleStepperNext}
                  fetchMessageTags={this.fetchMessageTags}
                  options={[...this.state.fbpages]}
                />
              ) : (
                <p>Please authorize first</p>
              )
            ) : null}
            {activestep === 1 ? (
              <SelectTag
                selected={this.state.selectedmessengertag}
                token={this.state.pageaccesstoken}
                setTag={this.setTag}
                step={this.handleStepperNext}
                messengerTags={this.state.messengertags}
                setMessageTags={this.setMessageTags}
              />
            ) : null}
            {activestep === 2 ? (
              selectedmessengertag ? (
                <CreateMessage
                  messages={this.state.messages}
                  setMessages={this.setMessages}
                />
              ) : null
            ) : null}
            {activestep === 3 ? (
              selectedmessengertag ? (
                <SendMessages
                  messages={this.state.messages}
                  candybar={this.displayCandybarMessage}
                  sendmessages={this.createMessageCreatives}
                />
              ) : null
            ) : null}
          </Fragment>
          <Candybar
            close={this.toggleCandybar}
            open={this.state.candybartoggle}
            message={this.state.candybarmessage}
          />
        </Fragment>
      );
    }

    public componentDidMount() {
      window.fbAsyncInit = window.fbAsyncInit || {};

      window.fbAsyncInit = () => {
        window.FB.init({
          ...this.state.fbapp.app,
        });
        this.setState({ FB: window.FB });
      };
      if (typeof FB === 'undefined') {
        ((d, s, id) => {
          const fjs = d.getElementsByTagName(s)[0] as Element;
          if (d.getElementById(id)) {
            return;
          }
          const js = d.createElement(s) as HTMLImageElement;
          js.id = id;
          js.src = '//connect.facebook.net/en_US/sdk.js';
          fjs.parentNode!.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
      }
    }

    private setFbPageAccessToken = (obj: any) => {
      // tslint:disable:no-console
      console.log('obj: ' + JSON.stringify(obj));
      if ('accounts' in obj) {
        this.setState({ fbpages: obj.accounts.data });
        // this.setState({
        //   fbPageAccounts: response.accounts,
        // });
      }
    };

    private setFbUserAccessToken = (response: any) => {
      if (response.status === 'connected') {
        this.setState({ authed: true });
        // tslint:disable:no-console
        console.log('Login success');
        FB.api('/me', { fields: 'accounts' }, (res: any) => {
          this.setFbPageAccessToken(res);
          console.log('Response is: ', res);
          if ('accounts' in res) {
            const arr = res.accounts.data;
            arr.map((obj: any) => {
              if (obj.name === 'Sarpe_diem') {
                FB.api(
                  obj.id,
                  'GET',
                  {
                    fields: 'instagram_business_account{username}',
                  },
                  (res2: any) => {
                    // const { instagram_business_account } = response;
                    // this.setState({
                    //   instagrambusinessaccount: [
                    //     ...this.state.instagrambusinessaccount,
                    //     instagram_business_account,
                    //   ],
                    // });
                    console.log('instaresp: ', res2);
                  }
                );
              }
            });
          }
        });
        this.setState({
          candybarmessage: 'Got User Access Token!',
          fbuseraccesstoken: response.authResponse.accessToken,
        });
        this.toggleCandybar();
      } else if (response.status === 'not_authorized') {
        console.log('Login not authorized');
      } else {
        console.log('Login WTF?');
        console.log('Response Status = ' + JSON.stringify(response));
      }
    };

    private toggleCandybar = () => {
      this.setState(prevState => ({
        candybartoggle: !prevState.candybartoggle,
      }));
    };

    private handleLogin = () => {
      FB.login(this.setFbUserAccessToken, {
        scope: this.state.fbapp.app.scope,
      });
    };

    private setMessages = (arr: any) => {
      this.setState({ messages: arr });
    };

    private handleLogout = () => {
      FB.logout(response => {
        console.log('user is now logged out');
      });
    };

    private handlePageSelection = (id: any) => {
      console.log('Chosen Page: ' + id.id);
      this.setState({
        candybarmessage: 'Selected FB Page!',
        pageaccesstoken: id.token,
        selectedfbpage: id.id,
      });
      this.toggleCandybar();
    };

    private setTag = (id: string) => {
      console.log('EVENTValue: ' + JSON.stringify(id));
      this.setState({
        candybarmessage: 'Selected Messenger Tag!',
        selectedmessengertag: id,
      });
      this.toggleCandybar();
    };

    private setMessageTags = (msg: string, res: any) => {
      console.log('CANDY: ' + msg);
      this.setState({
        candybarmessage: msg,
        messengertags: res.data,
      });
      this.toggleCandybar();
    };

    private displayCandybarMessage = (msg: string) => {
      this.setState({
        candybarmessage: msg,
      });
      this.toggleCandybar();
    };

    private handleStepperNext = () => {
      this.setState(state => ({
        activestep: state.activestep + 1,
      }));
    };
    private handleStepperBack = () => {
      this.setState(state => ({
        activestep: state.activestep - 1,
      }));
    };

    private fetchMessageTags = (pageAccessToken: string) => {
      FB.api(
        '/me/custom_labels',
        {
          access_token: pageAccessToken,
          fields: 'name',
        },
        (res: any) => {
          this.setMessageTags('Got Msger Tags!', res);
        }
      );
    };

    private createMessageCreatives = (msgs: any) => {
      const { pageaccesstoken } = this.state;

      console.log('These are the msgs: ' + JSON.stringify(msgs));
      const fn = function asyncFBapiCall(msg: any) {
        // sample async action
        return new Promise(resolve =>
          FB.api(
            '/me/message_creatives',
            'post',
            {
              access_token: pageaccesstoken,
              messages: [msg],
            },
            (res: any) => {
              resolve(res);
            }
          )
        );
      };

      // map over forEach since it returns
      const actions = msgs.map(fn);
      const results = Promise.all(actions); // pass array of promises

      results.then((data: any) => {
        let count = 0;
        this.setState({
          candybarmessage: 'Got all Message Creatives',
        });
        this.toggleCandybar();
        console.log(JSON.stringify(data));

        // naïve rate limiting
        const sleep = (ms: any) =>
          new Promise(resolve => {
            setTimeout(resolve, ms);
          });

        const myPromise = (obj: any) =>
          sleep(500).then(() => {
            count = count + 1;
            // console.log('Count is : ' + count);
            // console.log('done: ' + JSON.stringify(obj));
            // console.log('select msg tag: ' + this.state.selectedmessengertag);

            FB.api(
              '/me/broadcast_messages',
              'post',
              {
                access_token: this.state.pageaccesstoken,
                custom_label_id: this.state.selectedmessengertag,
                message_creative_id: obj.message_creative_id,
                messaging_type: 'MESSAGE_TAG',
                notification_type: count === 1 ? 'SILENT_PUSH' : 'NO_PUSH',
                tag: 'NON_PROMOTIONAL_SUBSCRIPTION',
              },
              (response: any) => {
                // console.log(JSON.stringify(response));
                //
                this.setState({
                  candybarmessage: 'Sent Message #' + count,
                });
                this.toggleCandybar();
              }
            );
          });

        // Resolve Array of promises sequentially
        data.reduce(
          (p: any, x: any) => p.then((t: any) => myPromise(x)),
          Promise.resolve()
        );
      });
    };
  }
);

export default DecoratedClass;
