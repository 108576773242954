import { WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Fab from '@material-ui/core/Fab';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import PhotoIcon from '@material-ui/icons/Photo';
import SaveIcon from '@material-ui/icons/Save';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import * as React from 'react';
import { useState } from 'react';
// const util = require('util');
// import MessageTextCard from './MessageTextCard';

interface IMessages {
  type: string;
  message?: string;
  messageobj?: IGenericmessage;
}

interface IGenericmessage {
  buttontext: string;
  imageurl: string;
  linkurl: string;
  subtitle: string;
  title: string;
}

interface IProps extends WithStyles<typeof styles> {
  messages: IMessages[];
  setMessages: (msgs: object[]) => void;
}

// interface IMessageState {
//   type: string;
//   message: string;
// }

// interface IEnumIMessageState extends Array<IMessageState> {}

const styles = createStyles({
  card: {
    margin: '2vw',
    minWidth: 275,
  },
  cardactions: {
    flexDirection: 'column',
  },
  exfab: {
    textAlign: 'center',
  },
  fab: {
    bottom: '10vw',
    position: 'fixed',
    right: '2vw',
  },
  fabaligner: {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  speedDial: {
    bottom: '10vw',
    position: 'fixed',
    right: '2vw',
  },
  textField: {
    width: '90vw',
  },
  title: {
    fontSize: 14,
  },
  type: {
    fontSize: 14,
  },
});

const actions = [
  { icon: <PhotoIcon />, name: 'Generic Msg', type: 'generic' },
  { icon: <AnnouncementIcon />, name: 'Text Msg', type: 'text' },
];

const DecoratedCreateMessage = withStyles(styles)((props: IProps) => {
  const defaultgenericmessage = {
    buttontext: '',
    imageurl: '',
    linkurl: '',
    subtitle: '',
    title: '',
  };

  const [open, setOpen] = useState(false);
  const [save, setSave] = useState(false);
  const [genericmessage, setGenericmessage] = useState<IGenericmessage>(
    defaultgenericmessage
  );
  // const [messages, setMessages] = useState<IEnumIMessageState>([]);
  const [currentMsg, setCurrentMsg] = useState('');
  const [currentMsgType, setCurrentMsgType] = useState('');

  const handleClick = (type: any) => {
    console.log(type);
    setOpen(!open);
    setSave(true);
    setCurrentMsgType(type);
  };

  const handleSave = (event: any) => {
    console.log(event);
    if (currentMsgType === 'text') {
      props.setMessages([
        ...props.messages,
        { type: currentMsgType, message: currentMsg },
      ]);
    } else if (currentMsgType === 'generic') {
      props.setMessages([
        ...props.messages,
        { type: currentMsgType, messageobj: { ...genericmessage } },
      ]);
    }
    setCurrentMsg('');
    setCurrentMsgType('');
    setGenericmessage(defaultgenericmessage);
    setSave(false);
  };

  const handleTextInput = (event: any) => {
    console.log(event.target.value);
    setCurrentMsg(event.target.value);
  };

  const handleTest = (item: string, event: any) => {
    if (item === 'buttontext') {
      const buttontextobj = {
        ...genericmessage,
        buttontext: event.target.value,
      };
      setGenericmessage(buttontextobj);
    } else if (item === 'imageurl') {
      const imageurlobj = { ...genericmessage, imageurl: event.target.value };
      setGenericmessage(imageurlobj);
    } else if (item === 'linkurl') {
      const linkurlobj = { ...genericmessage, linkurl: event.target.value };
      setGenericmessage(linkurlobj);
    } else if (item === 'subtitle') {
      const subtitleobj = { ...genericmessage, subtitle: event.target.value };
      setGenericmessage(subtitleobj);
    } else if (item === 'title') {
      const titleobj = { ...genericmessage, title: event.target.value };
      setGenericmessage(titleobj);
    }
    console.log('Generic IS: ' + JSON.stringify(genericmessage));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDeleteMessage = (event: any) => {
    const pos = event.currentTarget.dataset.index;
    parseInt(pos, 10);
    const arr = [...props.messages];
    arr.splice(pos, 1);
    props.setMessages(arr);
    console.log('Delete MSG: ' + pos);
    console.log('New Array: ' + JSON.stringify(arr));
  };

  const { classes } = props;

  return (
    <div>
      {props.messages.map((msg, index) => (
        <>
          {msg.type === 'text' ? (
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom={true}
                >
                  {msg.type.toUpperCase()}
                </Typography>
                <Typography component="p">{msg.message}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={handleDeleteMessage}
                  data-index={index}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          ) : null}
          {msg.type === 'generic' ? (
            <Card className={classes.card}>
              {msg.messageobj ? (
                <>
                  <CardMedia
                    className={classes.media}
                    image={msg.messageobj.imageurl}
                    title="Title"
                  />
                  <CardContent>
                    <Typography
                      className={classes.type}
                      color="textSecondary"
                      gutterBottom={true}
                    >
                      {msg.type.toUpperCase()}
                    </Typography>
                    <Typography variant='title'>
                      {msg.messageobj.title}
                    </Typography>
                    <Typography variant='subtitle1'>
                      {msg.messageobj.subtitle}
                    </Typography>
                    <div className={classes.fabaligner}>
                      <Fab
                        variant="extended"
                        aria-label="Link"
                        className={classes.exfab}
                        // tslint:disable-next-line jsx-no-lambda
                        onClick={() =>
                          window.open(msg.messageobj!.linkurl, '_blank')
                        }
                      >
                        {msg.messageobj.buttontext}
                      </Fab>
                    </div>
                  </CardContent>
                </>
              ) : null}
              <CardActions>
                <Button
                  size="small"
                  onClick={handleDeleteMessage}
                  data-index={index}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          ) : null}
        </>
      ))}
      {/*<MessageTextCard/>*/}
      {currentMsgType === 'text' ? (
        <Card className={classes.card}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom={true}
            >
              TEXT
            </Typography>
          </CardContent>
          <CardActions className={classes.cardactions}>
            <TextField
              multiline={true}
              className={classes.textField}
              id="outlined-simple-start-adornment"
              variant="outlined"
              label="Text Message"
              onChange={handleTextInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Msg</InputAdornment>
                ),
              }}
            />
          </CardActions>
        </Card>
      ) : null}
      {currentMsgType === 'generic' ? (
        <Card className={classes.card}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom={true}
            >
              GENERIC
            </Typography>
          </CardContent>
          <CardActions className={classes.cardactions}>
            <TextField
              id="outlined-title-input"
              label="Title"
              className={classes.textField}
              type="title"
              name="title"
              autoComplete="Title"
              margin="normal"
              variant="outlined"
              // tslint:disable-next-line jsx-no-lambda
              onChange={e => handleTest('title', e)}
              // onChange={(e) => {handleTest('title', e)}}
            />
            <TextField
              id="outlined-imageurl-input"
              label="Image URL"
              className={classes.textField}
              type="imageurl"
              name="imageurl"
              autoComplete="Image URL"
              margin="normal"
              variant="outlined"
              // tslint:disable-next-line jsx-no-lambda
              onChange={e => handleTest('imageurl', e)}
            />
            <TextField
              id="outlined-subtitle-input"
              label="Subtitle"
              className={classes.textField}
              type="subtitle"
              name="subtitle"
              autoComplete="Subtitle"
              margin="normal"
              variant="outlined"
              // tslint:disable-next-line jsx-no-lambda
              onChange={e => handleTest('subtitle', e)}
            />
            <TextField
              id="outlined-linkurl-input"
              label="Link URL"
              className={classes.textField}
              type="linkurl"
              name="linkurl"
              autoComplete="Link URL"
              margin="normal"
              variant="outlined"
              // tslint:disable-next-line jsx-no-lambda
              onChange={e => handleTest('linkurl', e)}
            />
            <TextField
              id="outlined-buttontext-input"
              label="ButtonText"
              className={classes.textField}
              type="buttontext"
              name="buttontext"
              autoComplete="Button Text"
              margin="normal"
              variant="outlined"
              // tslint:disable-next-line jsx-no-lambda
              onChange={e => handleTest('buttontext', e)}
            />
          </CardActions>
        </Card>
      ) : null}
      {!save ? (
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onBlur={handleClose}
          onClose={handleClose}
          onFocus={handleOpen}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          open={open}
          direction={'up'}
        >
          {actions.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              // tslint:disable-next-line jsx-no-lambda
              onClick={() => handleClick(action.type)}
              data-type={action.type}
            />
          ))}
        </SpeedDial>
      ) : null}
      {save ? (
        <Fab
          color="secondary"
          aria-label="Add"
          className={classes.fab}
          onClick={handleSave}
        >
          <SaveIcon />
        </Fab>
      ) : null}
    </div>
  );
});

export default DecoratedCreateMessage;
