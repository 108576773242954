import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';
import * as React from 'react';

interface ISelectTagProps {
  token: string;
  setTag: (id: string) => void;
  messengerTags: object[];
  setMessageTags: (msg: string, res: any) => void;
  step: any;
  selected: any;
}

// TODO: Correctly replace all 'any' types
const LongMenu = (props: ISelectTagProps) => {
  const { messengerTags, setTag, step, selected} = props;

  const handleSelection = (event: any) => {
    setTag(event.currentTarget.dataset.tag);
    step();
  };


  return (
      <div>
        {selected ?
          null:
        (<p>Please select Tag:</p>)
        }
        <List>
          {messengerTags.map((option: any, index: number) => (
            <ListItem
              button={true}
              key={option.id}
              data-index={index}
              data-tag={option.id}
              onClick={handleSelection}
              selected={selected === option.id}
            >
              <Avatar>
                <ImageIcon />
              </Avatar>
              <ListItemText primary={option.name} secondary={option.name} />
            </ListItem>
          ))}
        </List>
      </div>
  );
};

export default LongMenu;
