import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Fragment } from 'react';


interface ICandybarProps {
  close: () => void;
  open: boolean;
  message: string;
}

// TODO: Implement Snackbar stacking guidelines as per
// https://material.io/design/components/snackbars.html#snackbars-toasts-usage
const styles = (theme: any) => ({
  close: {
    height: theme.spacing.unit * 4,
    width: theme.spacing.unit * 4,
  },
});

const Candybar = (props: ICandybarProps) => {
  return (
    <Fragment>
      <Snackbar
        open={props.open}
        onClose={props.close}
        TransitionComponent={Fade}
        autoHideDuration={1500}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{props.message}</span>}
      />
    </Fragment>
  );
};

export default withStyles(styles)(Candybar);
