import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ImageIcon from "@material-ui/icons/Image";
import * as React from "react";

interface ISocialProps {
  token: string;
  options: any;
  select: any;
  selected: any;
  step: any;
  fetchMessageTags: any;
}

// TODO: Correctly replace all 'any' types
const LongMenu = (props: ISocialProps) => {
  const handleSelection = (event: any) => {
    // WTF?: Use 'currentTarget' instead of 'target' to access the datase of the
    // event - this is material ui weirdness...
    props.select(event.currentTarget.dataset);
    props.fetchMessageTags(event.currentTarget.dataset.token);
    props.step();
  };

  const { options, selected } = props;

  return (
    <div>
      {selected ?
        null
        :
        <p>Please select page:</p>
      }
      <List>
        {options.map((option: any, index: number) => (
          <ListItem
            button={true}
            key={option.id}
            data-id={option.id}
            data-token={option.access_token}
            data-index={index}
            onClick={handleSelection}
            selected={selected === option.id}
          >
            <Avatar>
              <ImageIcon />
            </Avatar>
            <ListItemText primary={option.name} secondary={option.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default LongMenu;
