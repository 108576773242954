import { WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import * as React from 'react';
// import { texttemplate } from './Template';

const styles = createStyles({
  button: {
    margin: '14vw',
  },
});

interface IMessages {
  type: string;
  message?: string;
  messageobj?: IGenericmessage;
}

interface IGenericmessage {
  buttontext: string;
  imageurl: string;
  linkurl: string;
  subtitle: string;
  title: string;
}

interface IArr {
  attachment?: {
    type: string
    payload: {
      template_type: string
      elements: object[];
    };
  };
  text?: string;
}


interface IEnumIArr extends Array<IArr> {}

interface ISendMessagesProps extends WithStyles<typeof styles> {
  messages: IMessages[];
  candybar: (msg: string) => void;
  sendmessages: (msgs: object) => void;
}

// const bla = {
//   buttontext: 'asdf asd',
//   imageurl:
//     'http://cdn2.spiegel.de/images/image-1364819-900_breitwand_180x67-cegv-1364819.jpg',
//   linkurl:
//     'http://cdn2.spiegel.de/images/image-1364819-900_breitwand_180x67-cegv-1364819.jpg',
//   subtitle: 'asdfasdfasdfasdf asdf ',
//   title: 'asdf sdf asd',
// };

const DecoratedSendMessages = withStyles(styles)(
  (props: ISendMessagesProps) => {
    const createMsg = (msg: any) => {
      console.log('msg = ' + JSON.stringify(msg));
      if (msg.type === 'text') {
        return {
          text: msg.message,
        };
      } else if (msg.type === 'generic') {
        return {
          attachment: {
            type: 'template',
            payload: {
              template_type: 'generic',
              elements: [
                {
                  title: msg.messageobj.title,
                  image_url: msg.messageobj.imageurl,
                  subtitle: msg.messageobj.subtitle,
                  buttons: [
                    {
                      type: 'web_url',
                      url: msg.messageobj.linkurl,
                      title: msg.messageobj.buttontext,
                    },
                  ],
                },
              ],
            },
          },
        };
      } else {
        return {
          text: 'error',
        };
      }
    };

    const sendMessages = () => {
      const arr: IEnumIArr = [];
      props.messages.map((msg, index) => {
        console.log('Msg Idx' + index);
        const tempmsg = createMsg(msg);
        arr.push(tempmsg);
        // console.log('CreateMsg: ' + createMsg(msg))
        // createMsg(msg);
      });
      console.log('Arr = ' + JSON.stringify(arr));
      props.sendmessages(arr);
    };

    const { classes } = props;

    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={sendMessages}
      >
        Send
        <SendIcon />
      </Button>
    );
  }
);

export default DecoratedSendMessages;
